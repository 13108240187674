import React, { useContext } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PersonIcon from '@material-ui/icons/Person'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import { registerGoogleAnalyticsWithHistory } from '../../utils/GoogleAnalytics'
import AppDrawer from '../AppDrawer'
import { undoSpoofUser, isSpoofedUser } from '../../utils/user'
import { undoSpoofUser as undoSpoofUserApi } from '../../api/users'
import logo from '../../images/360_logo.png'
import { UserContext } from '../../contexts/User'
import Help from './Help'
import UserFeedback from '../UserFeedback'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '85px',
    paddingBottom: 40 + 16,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 40 + 16,
    },
  },
  main: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#fff',
  },
  appBar: {
    color: '#000',
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  spoofUserWrapper: {
    backgroundColor: '#edf7ed',
    color: theme.palette.primary.dark,
    borderBottom: '1px solid',
    width: '100%',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  spoofUserContainer: {
    minHeight: 'calc(100vh - 57px)',
  },
  logo: {
    width: '100px',
    height: '45px',
  },
}));

function AppContainer(props) {
  const { children } = props
  const classes = useStyles();

  return (
    <Container
      component="main"
      id="main-content"
      maxWidth="md"
      className={clsx(classes.root)}
    >
      {children}
    </Container>
  );
}

export default function AppLayout({ children, ...others }) {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const { user } = useContext(UserContext)
  const classes = useStyles()
  registerGoogleAnalyticsWithHistory(useHistory(), user)

  const handleDrawerOpen = () => {
    setMobileOpen(true)
  }
  const handleDrawerClose = () => {
    setMobileOpen(false)
  }

  const handleUnSpoofUser = () => {
    undoSpoofUserApi().then((res) => {
      undoSpoofUser(res.user)
    })
  }

  if (!user) {
    return (
      <>
        <div>{children}</div>
        <Help />
      </>
    )
  }

  return (
    <>
      {isSpoofedUser() && (
        <Box p={1} className={classes.spoofUserWrapper}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container>
                <PersonIcon />
                <Typography
                  variant="subtitle1"
                  component="span"
                  className="ml-10"
                >
                  {`You are now viewing ${user.name} PMR.`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                startIcon={<SyncAltIcon />}
                onClick={handleUnSpoofUser}
              >
                Switch To Super Admin
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <div className={isSpoofedUser() ? `${classes.main} ${classes.spoofUserContainer}` : classes.main}>
        <Hidden lgUp>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="openDrawer"
                onClick={handleDrawerOpen}
                className="navIconClassName"
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="openDrawer"
                onClick={handleDrawerOpen}
                className="navIconClassName"
              >
                <img src={logo} className={classes.logo} alt="360_logo" />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Hidden>
        <AppDrawer
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          mobileOpen={mobileOpen}
          {...others}
        />
        <AppContainer>{children}</AppContainer>
      </div>
      <Help />
      <UserFeedback />
    </>
  );
}
