import axios from 'axios'
import storage from '../utils/storage'

const client = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
  },
})

const request = (options) => {
  const requestOptions = options
  const token = storage.get('Auth-Token')
  if (token) {
    requestOptions.headers = {
      ...requestOptions.headers,
      Authorization: `Bearer ${token}`,
    }
  }

  const onSuccess = (response) => response.data

  const onError = (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/app/login'
      storage.remove('Auth-Token')
      storage.remove('user')
    }
    return Promise.reject(error.response || error.message)
  }

  return client(requestOptions).then(onSuccess).catch(onError)
}

export default request
