import React, {
  useState, createContext, useEffect,
} from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { getUserCycles } from '../api/cycle'
import Loader from '../components/common/Loader'
import ShowError from '../components/common/ShowError'

export type Cycle = {
  id: number,
  name: string,
  description: string,
  /* eslint-disable camelcase */
  computed_org: string,
  planned_start_date: string,
  planned_stage_1_end_date: string,
  planned_stage_2_end_date: string,
  planned_stage_3_end_date: string,
  planned_end_date: string,
  created_at: string,
  updated_at: string,
  start_date: string,
  state: string,
  max_peers: string,
  has_calibration: boolean,
  planned_stage_4_end_date: string,
  scale_answer_template?: string,
  /* eslint-enable camelcase */
}

export type CycleContextProps = {
  cycles: Cycle[],
  isLoadingCycles: boolean,
  handleCycleChange : (cycleId: number) => void,
  currentCycle: Cycle,
  cycleStateIs: (cycleState: string) => boolean,
  getCycleById: (cycleId: number) => Cycle,
}

type CycleProviderProps = {
  children: React.ReactNode
}

export const CyclesContext = createContext<CycleContextProps>({
  cycles: [],
  isLoadingCycles: true,
  handleCycleChange: () => {},
  currentCycle: null,
  cycleStateIs: () => false,
  getCycleById: () => null,
})

const getCycleByCycleId = (
  cycles: Cycle[], cycleId: number,
) => cycles.find((cycle) => cycle.id === cycleId)

export function CyclesProvider({ children }: CycleProviderProps) {
  const [isLoadingCycles, setIsLoadingCycles] = useState(true)
  const [cycles, setCycles] = useState<Cycle[]>([])
  const [currentCycle, setCurrentCycle] = useState<Cycle>(null)
  const [loadingCyclesError, setLoadingCyclesError] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)

  const sortCycles = (currentCycles: Cycle[]) => currentCycles.sort((cycle1: Cycle, cycle2: Cycle) => {
    if (cycle1.state === 'communication' && cycle2.state !== 'communication') return 1
    if (cycle1.state !== 'communication' && cycle2.state === 'communication') return -1
    if (cycle1.start_date && !cycle2.start_date) return 1
    if (!cycle1.start_date && cycle2.start_date) return -1
    if (cycle1.planned_end_date > cycle2.planned_end_date) {
      return -1
    }
    if (cycle1.planned_end_date < cycle2.planned_end_date) {
      return 1
    }
    return 0
  })

  const updateCycle = (currentCycles: Cycle[]) => {
    const urlCycleId = parseInt(searchParams.get('cycle_id'), 10)
    const currentCycleId = currentCycle ? currentCycle.id : null
    if (urlCycleId !== currentCycleId) {
      const defaultCycle = urlCycleId ? getCycleByCycleId(currentCycles, urlCycleId) : currentCycle || currentCycles[0]
      setCurrentCycle(defaultCycle)
      if (!urlCycleId && defaultCycle) {
        history.replace(`${location.pathname}?cycle_id=${defaultCycle.id}`)
      }
    }
  }

  useEffect(() => {
    getUserCycles().then((res) => {
      const sortedCycle = sortCycles(res.cycles)
      setCycles(sortedCycle)
      updateCycle(sortedCycle)
      setIsLoadingCycles(false)
    }).catch((error) => {
      setLoadingCyclesError(error)
      setIsLoadingCycles(false)
    })
  }, [])

  useEffect(() => {
    if (!isLoadingCycles) {
      updateCycle(cycles)
    }
  }, [location.search])

  const handleCycleChange = (cycleId: number) => {
    history.push(`${location.pathname}?cycle_id=${cycleId}`)
  }

  const cycleStateIs = (cycleState: string) => currentCycle.state === cycleState
  const getCycleById = (id: number) => cycles.find((cycle) => cycle.id === id)

  return (
    <CyclesContext.Provider
      value={{
        cycles,
        isLoadingCycles,
        handleCycleChange,
        currentCycle,
        cycleStateIs,
        getCycleById,
      }}
    >
      {isLoadingCycles
        ? <Loader />
        : (
          <>
            {loadingCyclesError ? <ShowError error={loadingCyclesError} />
              : (
                <>
                  {currentCycle
                    ? <>{children}</>
                    : (
                      <div>
                        {`${cycles.length
                          ? 'Invalid Cycle ID' : 'No cycles present'}`}
                      </div>
                    )}
                </>
              )}
          </>
        )}
    </CyclesContext.Provider>
  )
}
