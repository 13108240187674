import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Hidden from '@material-ui/core/Hidden'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Power from '@material-ui/icons/PowerSettingsNew'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import {
  NavLink, useHistory, useLocation,
} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { logoutUser } from '../../api/users'
import getUserRoutesConfig from '../../utils/routes'
import { isSpoofedUser } from '../../utils/user'
import AppDomain from '../../constants/AppDomain.js.erb'
import logo from '../../images/360_logo.png'
import { RouteConfigContext } from '../../contexts/RouteConfig'
import { ConfigContext } from '../../contexts/config'
import { UserContext } from '../../contexts/User'
import CoachMarks from '../common/CoachMarks'
import { sidebarRoutesSteps } from '../common/CoachMarks/CoachMarkTypes'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 260,
    backgroundColor: '#F6F7FA',
    height: 'calc(100vh - 16px)',
    borderRight: 'none',
    borderRadius: '8px',
    top: '8px',
    left: '8px',
  },
  paperWithSpoofUser: {
    height: 'calc(100vh - 57px)',
    top: '57px',
    width: 260,
    backgroundColor: theme.palette.background.level2,
    // eslint-disable-next-line max-len
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.12),0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
  },
  drawerContent: {
    height: '100%',
    flexWrap: 'nowrap',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(2),
  },
  toolbar: {
    padding: theme.spacing(3),
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: 260,
    },
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    width: '100%',
    justifyContent: 'flex-start',
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  navParent: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    display: 'flex',
    width: '100%',
    padding: '10px 20px',
    justifyContent: 'flex-start',
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  activeButton: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  listNoPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  logo: {
    width: '150px',
    height: '53px',
  },
  version: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0.5),
    textDecoration: 'none',
  },
  expandBtn: {
    position: 'absolute',
    right: '20px',
  },
  singleNav: {
    padding: '10px 20px !important',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    padding: theme.spacing(1),
  },
}))

function AppDrawerNavItem(props) {
  const { route, depth, isSingle } = props
  const classes = useStyles()
  const classNames = [classes.item, route.coachMarkClassName].filter(String).join(' ')
  const location = useLocation()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const style = {
    paddingLeft: 8 * (3 + 2 * depth),
  }

  const Icon = route.icon

  const popoverOpen = Boolean(anchorEl)

  const InfoPopover = ({ infoText }) => (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.popoverPaper,
      }}
      open={popoverOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography style={{ fontSize: '14px', maxWidth: '500px' }}>{infoText}</Typography>
    </Popover>
  )

  if (route.externalLink) {
    return (
      <ListItem className={classes.item} disableGutters>
        <Button
          component="a"
          href={route.pathname}
          disableTouchRipple
          target="_blank"
          classes={{ root: classes.button }}
          style={style}
        >
          {route.title}
        </Button>
      </ListItem>
    )
  }

  return (
    <>
      <ListItem className={classNames} disableGutters>
        <Button
          component={NavLink}
          to={{ pathname: route.pathname, state: { prevPath: location.pathname } }}
          disableTouchRipple
          activeClassName={classes.active}
          classes={{ root: classes.button }}
          style={style}
          startIcon={route.icon ? <Icon /> : null}
          className={clsx({
            [classes.singleNav]: isSingle,
          })}
          data-testid={`side-nav-${route.title}`}
          aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {route.title}
        </Button>
      </ListItem>
      {route.infoText && <InfoPopover infoText={route.infoText} />}
    </>
  )
}

function Routes({ route }) {
  const classes = useStyles()
  const location = useLocation()
  const hasSubRoutes = route.subRoutes ? route.subRoutes.length > 0 : false
  const parentActive = hasSubRoutes && route.subRoutes
    .find((subRoute) => location.pathname === subRoute.pathname || location.pathname.includes(subRoute.pathname))

  const [open, setOpen] = React.useState(Boolean(parentActive))
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleClick = () => {
    setOpen(!open)
  }

  useEffect(() => {
    if (parentActive) {
      setOpen(true)
    }
  }, [parentActive])

  const popoverOpen = Boolean(anchorEl)
  const Icon = route.icon

  const InfoPopover = ({ infoText }) => (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.popoverPaper,
      }}
      open={popoverOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography style={{ fontSize: '14px', maxWidth: '500px' }}>{infoText}</Typography>
    </Popover>
  )

  const filterCoachMarkSteps = (coachMarkTypeTargets) => {
    if (!coachMarkTypeTargets || coachMarkTypeTargets.length === 0) {
      return undefined
    }

    return sidebarRoutesSteps.filter(
      (step) => coachMarkTypeTargets.includes(step.target.substring(1)),
    )
  }

  if (hasSubRoutes) {
    const coachMarkTypeTargets = route.subRoutes.map((subRoute) => subRoute.coachMarkClassName).filter(Boolean)
    const filteredCoachMarkSteps = filterCoachMarkSteps(coachMarkTypeTargets)

    return (
      <List className={classes.listNoPadding} key={route.pathname} data-testid="">
        <Button
          className={clsx({
            [classes.navParent]: true,
            [classes.activeButton]: parentActive,
          })}
          onClick={handleClick}
          startIcon={route.icon ? <Icon /> : null}
          aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {route.title}
          {open
            ? <ExpandLess classes={{ root: classes.expandBtn }} />
            : <ExpandMore classes={{ root: classes.expandBtn }} />}
        </Button>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {route.subRoutes.map((subRoute) => <AppDrawerNavItem key={subRoute.pathname} route={subRoute} depth={1} />)}
          {filteredCoachMarkSteps && <CoachMarks coachMarkSteps={filteredCoachMarkSteps} />}
        </Collapse>
        {route.infoText && <InfoPopover infoText={route.infoText} />}
      </List>
    )
  }

  return (
    <AppDrawerNavItem route={route} depth={0} key={route.pathname} isSingle />
  )
}

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

function AppDrawer(props) {
  const { mobileOpen, onOpen, onClose } = props
  const classes = useStyles()
  const { removeUser, user } = useContext(UserContext)
  const { routeOptions } = useContext(RouteConfigContext)
  const config = useContext(ConfigContext)
  const history = useHistory()

  const logout = () => {
    logoutUser().then(() => {
      removeUser()
      history.push('/app/login')
    })
  }

  const routes = getUserRoutesConfig(user.role, routeOptions, config)

  const drawer = (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.drawerContent}
    >
      <Grid item>
        <div className={classes.toolbar}>
          <NavLink to="/app/home">
            <img src={logo} className={classes.logo} alt="360_logo" />
          </NavLink>
        </div>
        <List>
          {routes.map((route) => (<Routes key={route.title} route={route} />))}
        </List>
      </Grid>
      <Grid item>
        { user && (
          <div className={classes.footer}>
            <Grid container alignItems="center" wrap="nowrap">
              <Avatar src={user.profile_image_url} alt={user.name} />
              <div className="ml-10" data-testid="side-nav-user-name">{user.name}</div>
            </Grid>
            <IconButton onClick={logout} data-testid="logout-btn">
              <Power fontSize="small" />
            </IconButton>
          </div>
        )}
        <div className={classes.version}>
          <Box mb={1} mt={1}>
            <Typography variant="body2">
              <span>{`v${AppDomain.PMR_APP_VERSION}`}</span>
            </Typography>
          </Box>
        </div>
      </Grid>
    </Grid>
  )

  return (
    <nav className={classes.drawer} aria-label="mainNavigation">
      <Hidden lgUp implementation="js">
        <SwipeableDrawer
          classes={{ paper: isSpoofedUser() ? classes.paperWithSpoofUser : classes.paper }}
          disableBackdropTransition={!iOS}
          variant="temporary"
          open={mobileOpen}
          onOpen={onOpen}
          onClose={onClose}
        >
          {drawer}
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{ paper: isSpoofedUser() ? classes.paperWithSpoofUser : classes.paper }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  )
}

export default AppDrawer
