const RailsRouter = {
  

  rootPath: () => '/app',

  adminCyclesPath: () => '/api/v1/admin/cycles',
  adminCyclePath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:id'
    return pathTemplate.replace(':id', cycleId.toString())
  },

  cyclesPath: () => '/api/v1/cycles',

  tokensPath: () => '/api/v1/tokens',

  configPath: () => '/api/v1/config',

  tokenLogoutPath: () => '/api/v1/tokens',

  routeConfigPath: () => '/api/v1/route_config',

  userCoachmarksPath: () => '/api/v1/coachmarks',

  adminUserPath: (userId) => {
    const pathTemplate = '/api/v1/admin/users/:id'
    return pathTemplate.replace(':id', userId.toString())
  },

  adminUsersPath: (query) => {
    const baseUrl = '/api/v1/admin/users'
    let url = ''
    if (query.role) {
      url = query.role.map((role) => `role[]=${role}`)
        .join('&')
    } else if (query.email) {
      url = `q=${query.email}`
    } else if (query.searchQuery) {
      url = `q=${query.searchQuery}`
    }
    return `${baseUrl}?${url}`
  },

  adminCycleStartPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/start'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminCycleProgressToSpuFeedbackPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/progress_to_spu_feedback'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminCycleProgressToManagerFeedbackPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/progress_to_manager_feedback'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminCycleProgressToCalibrationPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/progress_to_calibration'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminCycleProgressToCommunicationPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/progress_to_communication'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  cycleMyInfoPath: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/my_info'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  cycleMyPanelsPath: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/panels'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  newCycleMyPanelsPath: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/panels/new'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  participantPanelUpdatePath: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/panels'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  cycleParticipantPath: (cycleId, participantEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants/:email@foo.com'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', participantEmail.toString())
  },

  cycleParticipantsPath: (cycleId, query) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants?q=:query'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':query', query)
  },

  managerDirectReportPanelPath: (cycleId, directReportEmail) => {
    // eslint-disable-next-line quotes
    const pathTemplate = `/api/v1/cycles/:cycle_id/direct_reports/:email@foo.com/panel`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', directReportEmail.toString())
  },

  managerDirectReportCyclesPath: (cycleId, directReportEmail) => {
    // eslint-disable-next-line quotes
    const pathTemplate = `/api/v1/cycles/:cycle_id/direct_reports/:email@foo.com/cycles`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', directReportEmail.toString())
  },

  managerDirectReportMandatoryReviewersPath: (cycleId, directReportEmail) => {
    // eslint-disable-next-line quotes
    const pathTemplate = `/api/v1/cycles/:cycle_id/direct_reports/:email@foo.com/panel/new`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', directReportEmail.toString())
  },

  managerDirectReportPanelUpdatePath: (cycleId, directReportEmail) => {
    // eslint-disable-next-line quotes
    const pathTemplate = `/api/v1/cycles/:cycle_id/direct_reports/:email@foo.com/panel`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', directReportEmail.toString())
  },

  managerDirectReportPanelApprovePath: (cycleId, directReportEmail) => {
    // eslint-disable-next-line quotes
    const pathTemplate = `/api/v1/cycles/:cycle_id/direct_reports/:email@foo.com/panel/approve`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', directReportEmail.toString())
  },

  getFeedbacks: (cycleId) => {
    // eslint-disable-next-line quotes
    const pathTemplate = `/api/v1/cycles/:cycle_id/feedbacks`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  createFeedbackPath: (cycleId, revieweeEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants/:email@foo.com/feedback'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', revieweeEmail.toString())
  },

  getFeedbackPath: (cycleId, revieweeEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants/:email@foo.com/feedback'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', revieweeEmail.toString())
  },

  updateFeedbackPath: (cycleId, revieweeEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants/:email@foo.com/feedback'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', revieweeEmail.toString())
  },

  submitFeedbackPath: (cycleId, revieweeEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants/:email@foo.com/feedback/submit'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', revieweeEmail.toString())
  },

  adminCycleParticipantsPath: (cycleId, searchQuery) => {
    const pathTemplate = `/api/v1/admin/cycles/:cycle_id/participants?q=${searchQuery}`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminCycleParticipantUpdatePromotionDecisionPath: (cycleId, participantEmail) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/participants/:email@foo.com/promotion_decision'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', participantEmail)
  },

  adminCycleParticipantPath: (cycleId, participantEmail) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/participants/:email@foo.com'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', participantEmail)
  },

  adminCycleParticipantFeedbacksSummaryDownloadPath: (cycleId, participantEmail) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/participants/:email@foo.com/feedbacks_summary/download'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', participantEmail)
  },

  getManagerDirectReportFeedbacksPath: (cycleId, directReportEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/direct_reports/:email@foo.com/feedbacks'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', directReportEmail.toString())
  },

  getManagerDirectReportFeedbackSummaryPath: (cycleId, directReportEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/direct_reports/:email@foo.com/feedbacks_summary'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', directReportEmail.toString())
  },

  getSecondaryReportFeedbackSummaryPath: (cycleId, secondaryReportEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/secondary_reports/:email@foo.com/feedbacks_summary'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', secondaryReportEmail.toString())
  },

  getManagerDirectReportFeedbackPath: (cycleId, directReportEmail, reviewerEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/direct_reports/:email@foo.com/feedbacks/:reviewer_email@foo.com'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', directReportEmail.toString())
      .replace(':reviewer_email@foo.com', reviewerEmail.toString())
  },

  adminCycleUploadParticipantJobs: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/upload_participant_jobs'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminCycleUpsertParticipantJobs: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/upsert_participant_jobs'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminCycleUploadThermaScanReportJobs: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/upload_therma_scan_report_jobs'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminCycleUploadQuestionnaireJobs: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/upload_questionnaire_jobs'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  getAdminCycleQuestionsPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/questions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminCycleDownloadParticipantJobs: (cycleId, query) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/download_participant_jobs'
    const url = pathTemplate.replace(':cycle_id', cycleId.toString())
    return `${url}${query || ''}`
  },

  getAdminCycleDownloadParticipantJob: (cycleId, jobId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/download_participant_jobs/:job_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':job_id', jobId.toString())
  },

  getAdminStatisticsPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/statistics'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  createNudgeJobPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/nudge_jobs'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  acceptPeerPath: (peerId) => {
    const pathTemplate = '/api/v1/peers/:peer_id/accept'
    return pathTemplate.replace(':peer_id', peerId.toString())
  },

  declinePeerPath: (peerId) => {
    const pathTemplate = '/api/v1/peers/:peer_id/decline'
    return pathTemplate.replace(':peer_id', peerId.toString())
  },

  getParticipantFeedbackSummaryPath: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants/feedbacks_summary'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  getParticipantDashboardPath: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/dashboard'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  deleteCyclePath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:id'
    return pathTemplate.replace(':id', cycleId.toString())
  },

  calibrationSessionsPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/calibration_sessions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  calibrationSessionsAllParticipantsPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/calibration_sessions/all_participants'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  calibrationSessionPath: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/calibration_sessions/session_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace('session_id', sessionId.toString())
  },

  calibrationSessionParticipantsPath: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/calibration_sessions/session_id/session_participants'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace('session_id', sessionId.toString())
  },

  activateCalibrationSessionPath: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/calibration_sessions/session_id/activate'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace('session_id', sessionId.toString())
  },

  closeAllAdminCycleCalibrationSessionsPath: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/calibration_sessions/close_all'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  participantWithFeedbackCountPath: (cycleId, participantEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants/:email@foo.com/feedback_received_count'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', participantEmail.toString())
  },

  getAuditLogsPath: (limit, offset, searchQuery) => {
    const searchParam = searchQuery ? `&search_query=${searchQuery}` : ''
    return `/api/v1/admin/audits?limit=${limit}&offset=${offset}${searchParam}`
  },

  getManagerDirectReportFeedbackSummaryDownloadPath: (cycleId, directReportEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/direct_reports/:email@foo.com/feedbacks_summary/download'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', directReportEmail.toString())
  },

  getSecondaryReportFeedbackSummaryDownloadPath: (cycleId, secondaryReportEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/secondary_reports/:email@foo.com/feedbacks_summary/download'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', secondaryReportEmail.toString())
  },

  getParticipantFeedbackSummaryDownloadPath: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants/feedbacks_summary/download'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  calibrationSessionAdminsPath: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/calibration_sessions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  calibrationSessionAdminsSessionPath: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/calibration_sessions/:session_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString())
  },

  calibrationSessionAdminsSessionParticipantsPath: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/calibration_sessions/:session_id/participants'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString())
  },

  calibrationSessionAdminsSessionParticipantPath: (cycleId, sessionId, participantId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/calibration_sessions/:session_id/participants/:participant_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString()).replace(':participant_id', participantId.toString())
  },

  calibrationSessionAdminsSessionParticipantFeedbackSummaryPath: (cycleId, sessionId, email) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/calibration_sessions/:session_id/participants/:email@foo.com/feedbacks_summary'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString()).replace(':email@foo.com', email.toString())
  },

  calibrationSessionAdminsSessionParticipantCyclesPath: (cycleId, sessionId, participantId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/calibration_sessions/:session_id/participants/:participant_id/cycles'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString()).replace(':participant_id', participantId.toString())
  },

  submitCalibrationRatingPath: (cycleId, sessionId, participantId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/calibration_sessions/:session_id/participants/:participant_id/calibration_rating'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString()).replace(':participant_id', participantId.toString())
  },

  updateAdminCycleParticipantCalibratedRatingPath: (cycleId, participantEmail) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/participants/:email@foo.com/calibrated_rating'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', participantEmail.toString())
  },

  submitCalibrationSessionPath: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/calibration_sessions/:session_id/progress_to_review'
    return pathTemplate.replace(':cycle_id', cycleId.toString()).replace(':session_id', sessionId.toString())
  },

  moveParticipantsCalibrationSessionPath: (cycleId, sessionId, targetSessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/calibration_sessions/:session_id/move_participants_to/:target_session_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString())
      .replace(':target_session_id', targetSessionId.toString())
  },

  signOffPartyCalibrationSessionsPath: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_sign_off_party/calibration_sessions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  signOffPartyCalibrationSessionPath: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_sign_off_party/calibration_sessions/:session_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString()).replace(':session_id', sessionId.toString())
  },

  signOffPartyCalibrationSessionParticipantsPath: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_sign_off_party/calibration_sessions/:session_id/participants'
    return pathTemplate.replace(':cycle_id', cycleId.toString()).replace(':session_id', sessionId.toString())
  },

  signOffPartyCalibrationSessionParticipantPath: (cycleId, sessionId, participantId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_sign_off_party/calibration_sessions/:session_id/participants/:participant_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString()).replace(':participant_id', participantId.toString())
  },

  signOffPartyCalibrationSessionParticipantFeedbackSummaryPath: (cycleId, sessionId, email) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_sign_off_party/calibration_sessions/:session_id/participants/:email@foo.com/feedbacks_summary'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString()).replace(':email@foo.com', email.toString())
  },

  signOffPartySessionParticipantCyclesPath: (cycleId, sessionId, participantId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_sign_off_party/calibration_sessions/:session_id/participants/:participant_id/cycles'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString()).replace(':participant_id', participantId.toString())
  },

  closeCalibrationSessionPath: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_sign_off_party/calibration_sessions/:session_id/progress_to_close'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString())
  },

  revisionRequestedCalibrationSessionPath: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_sign_off_party/calibration_sessions/:session_id/progress_to_revision_requested'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString())
  },

  adjustCalibratedFeedbackPath: (cycleId, revieweeEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants/:email@foo.com/feedback/adjust'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', revieweeEmail.toString())
  },

  getParticipantPeersFeedbackSummaryPath: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/participants/feedbacks_summary/peers'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminGetParticipantPanelNew: (cycleId, email) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/:email@foo.com/panel/new'
    return pathTemplate.replace(':cycle_id', cycleId.toString()).replace(':email@foo.com', email.toString())
  },

  adminGetParticipantPanel: (cycleId, email) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/:email@foo.com/panel'
    return pathTemplate.replace(':cycle_id', cycleId.toString()).replace(':email@foo.com', email.toString())
  },

  adminUpdateParticipantPanel: (cycleId, email) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/:email@foo.com/panel'
    return pathTemplate.replace(':cycle_id', cycleId.toString()).replace(':email@foo.com', email.toString())
  },

  inactiveParticipantReport: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/decision_support/admin/inactive_participants'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminDownloadParticipantsReport: (cycleId, metricType) => {
    const pathTemplate = `/api/v1/cycles/:cycle_id/decision_support/admin/download_participants_report?metric_type=${metricType}`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  getPeerStats: (email) => {
    const pathTemplate = '/api/v1/performance_dashboard/peer_stats/:email@foo.com'
    return pathTemplate.replace(':email@foo.com', email.toString())
  },

  performanceDashboardUser: (email) => {
    const pathTemplate = '/api/v1/performance_dashboard/users/:email@foo.com'
    return pathTemplate.replace(':email@foo.com', email.toString())
  },

  getPerformanceStats: (email) => {
    const pathTemplate = '/api/v1/performance_dashboard/users/:email@foo.com/performance_ratings'
    return pathTemplate.replace(':email@foo.com', email.toString())
  },

  getDirectReportPerformanceStats: (email) => {
    const pathTemplate = '/api/v1/performance_dashboard/users/:email@foo.com/direct_reports_performance_ratings'
    return pathTemplate.replace(':email@foo.com', email.toString())
  },

  getUpwardStats: (email) => {
    const pathTemplate = '/api/v1/performance_dashboard/upward_stats/:email@foo.com'
    return pathTemplate.replace(':email@foo.com', email.toString())
  },

  getSignOffPartyParticipants: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_sign_off_party/activated_calibration_sessions_participants'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  getSessionAdminParticipants: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/all_calibration_sessions_participants'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  moveSessionsInReviewToClosed: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_sign_off_party/move_all_sessions_in_review_to_closed'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  populateParticipants: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/populate_participants_jobs'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  usersPath: (query) => {
    const baseUrl = '/api/v1/users'
    const queryString = `q=${query.searchQuery}${query.nominee_email ? `&nominee_email=${query.nominee_email}` : ''}`
    return `${baseUrl}?${queryString}`
  },

  usersHasDirectReportPath: () => '/api/v1/users/has_direct_report',

  sessionsAdminTerminateParticipant: (cycleId, sessionId, participantId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_admin/calibration_sessions/session_id/participants/participant_id/terminate_participant'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace('session_id', sessionId.toString())
      .replace('participant_id', participantId.toString())
  },

  promotionNomination: (cycleId, directReportEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/direct_reports/:email@foo.com/promotion_nominations'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':email@foo.com', directReportEmail.toString())
  },

  adminPromotionNominations: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/promotion_calibration_sessions/promotion_nominations'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  adminPromotionCalibrationSessions: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/promotion_calibration_sessions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  getEndorsements: () => '/api/v1/endorsements',

  acceptEndorsement: (cycleId, nominationId, endorserId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/endorsements/nominations/:nomination_id/endorsements/:endorser_id/accept'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':nomination_id', nominationId.toString())
      .replace(':endorser_id', endorserId.toString())
  },

  rejectEndorsement: (cycleId, nominationId, endorserId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/endorsements/nominations/:nomination_id/endorsements/:endorser_id/reject'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':nomination_id', nominationId.toString())
      .replace(':endorser_id', endorserId.toString())
  },

  getEndorsement: (cycleId, endorsementId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/endorsements/:endorsement_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':endorsement_id', endorsementId.toString())
  },

  updateEndorsement: (cycleId, nominationId, endorserId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/endorsements/nominations/:nomination_id/endorsements/:endorser_id/endorse'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':nomination_id', nominationId.toString())
      .replace(':endorser_id', endorserId.toString())
  },

  hrPartnerGetNominations: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/promotion_nominations'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  calibrationSessions: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/calibration_sessions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  calibrationSession: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/calibration_sessions/:session_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString()).replace(':session_id', sessionId.toString())
  },

  hrPartnerGetAllParticipants: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/calibration_sessions/session_id/all_participants'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace('session_id', sessionId.toString())
  },

  hrPartnerCreateSession: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/promotion_calibration_sessions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  hrPartnerActivateRatingCalibrationSession: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/calibration_sessions/session_id/activate'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace('session_id', sessionId.toString())
  },

  hrPartnerupdateSession: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/promotion_calibration_sessions/:id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':id', sessionId.toString())
  },

  hrPartnerGetSessions: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/promotion_calibration_sessions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  hrPartnerGetSession: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/promotion_calibration_sessions/:id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':id', sessionId.toString())
  },

  hrPartnerActivateSession: (cycleId, sessionId, event) => {
    const pathTemplate = `/api/v1/cycles/:cycle_id/hr_partner/promotion_calibration_sessions/:promotion_calibration_session_id/transition?event=${event}`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_calibration_session_id', sessionId.toString())
  },

  moveParticipantsPromotionSessionPath: (cycleId, sessionId, targetSessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/promotion_calibration_sessions/:session_id/move_participants_to/:target_session_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString())
      .replace(':target_session_id', targetSessionId.toString())
  },

  hrPartnerCreateCalibrationDetails: (cycleId, sessionId, nominationId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/promotion_calibration_sessions/:promotion_calibration_session_id/promotion_nominations/:promotion_nomination_id/promotion_details'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_calibration_session_id', sessionId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
  },

  hrPartnerUpdateCalibrationDetails: (cycleId, sessionId, nominationId, promotionDetailId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/promotion_calibration_sessions/:promotion_calibration_session_id/promotion_nominations/:promotion_nomination_id/promotion_details/:id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_calibration_session_id', sessionId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
      .replace(':id', promotionDetailId.toString())
  },

  hrPartnerGetFeedbackSummary: (cycleId, nominationId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/promotion_nominations/:promotion_nomination_id/feedbacks_summary'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
  },

  hrPartnerGetCycles: (cycleId, nominationId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/promotion_nominations/:promotion_nomination_id/nominee_cycles'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
  },

  promotionCalibrationPanelistSessions: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_panelist/promotion_calibration_sessions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  promotionCalibrationPanelistSession: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_panelist/promotion_calibration_sessions/:id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':id', sessionId.toString())
  },

  promotionCalibrationPanelistGetFeedbackSummary: (cycleId, sessionId, nominationId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_panelist/promotion_nominations/:promotion_nomination_id/promotion_calibration_session/:promotion_calibration_session_id/feedbacks_summary'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_calibration_session_id', sessionId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
  },

  promotionCalibrationPanelistGetCycles: (cycleId, sessionId, nominationId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_panelist/promotion_nominations/:promotion_nomination_id/promotion_calibration_session/:promotion_calibration_session_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_calibration_session_id', sessionId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
  },

  promotionCalibrationSignOffPartySessions: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_sign_off_party/promotion_calibration_sessions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  promotionCalibrationSignOffPartySession: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_sign_off_party/promotion_calibration_sessions/:id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':id', sessionId.toString())
  },

  promotionCalibrationSignOffPartyGetFeedbackSummary: (cycleId, sessionId, nominationId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_sign_off_party/promotion_nominations/:promotion_nomination_id/promotion_calibration_session/:promotion_calibration_session_id/feedbacks_summary'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_calibration_session_id', sessionId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
  },

  promotionCalibrationSignOffPartyGetCycles: (cycleId, sessionId, nominationId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_sign_off_party/promotion_nominations/:promotion_nomination_id/promotion_calibration_session/:promotion_calibration_session_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_calibration_session_id', sessionId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
  },

  signOffPartyAcceptPromotionCalibationSession: (cycleId, sessionId, event) => {
    const pathTemplate = `/api/v1/cycles/:cycle_id/promotion_calibration_session_sign_off_party/promotion_calibration_sessions/:promotion_calibration_session_id/transition?event=${event}`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_calibration_session_id', sessionId.toString())
  },

  signOffPartyRejectPromotionCalibationSession: (cycleId, sessionId, event) => {
    const pathTemplate = `/api/v1/cycles/:cycle_id/promotion_calibration_session_sign_off_party/promotion_calibration_sessions/:promotion_calibration_session_id/transition?event=${event}`
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_calibration_session_id', sessionId.toString())
  },

  panelistAddComment: (cycleId, nominationId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_panelist/promotion_nominations/:promotion_nomination_id/panelist_comments'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
  },

  signOffPartyAddComment: (cycleId, nominationId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_sign_off_party/promotion_nominations/:promotion_nomination_id/sign_off_party_comments'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
  },

  signOffPartyEditComment: (cycleId, nominationId, commentId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_sign_off_party/promotion_nominations/:promotion_nomination_id/sign_off_party_comments/:id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
      .replace(':id', commentId.toString())
  },

  panelistEditComment: (cycleId, nominationId, commentId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/promotion_calibration_session_panelist/promotion_nominations/:promotion_nomination_id/panelist_comments/:id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':promotion_nomination_id', nominationId.toString())
      .replace(':id', commentId.toString())
  },

  ratingCalibrationPanelistGetSessions: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_panelist/calibration_sessions'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  ratingCalibrationPanelistGetSession: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_panelist/calibration_sessions/:id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':id', sessionId.toString())
  },

  ratingCalibrationPanelistGetActivatedSessionsParticipants: (cycleId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_panelist/activated_calibration_sessions_participants'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  ratingCalibrationPanelistGetSessionsParticipants: (cycleId, sessionId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_panelist/calibration_sessions/session_id/participants'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace('session_id', sessionId.toString())
  },

  ratingCalibrationPanelistGetSessionsParticipant: (cycleId, sessionId, participantId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_panelist/calibration_sessions/session_id/participants/participant_id'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace('session_id', sessionId.toString())
      .replace('participant_id', participantId.toString())
  },

  ratingCalibrationPanelistGetFeedbackSummary: (cycleId, sessionId, participantEmail) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_panelist/calibration_sessions/:session_id/participants/:email@foo.com/feedbacks_summary'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString()).replace(':email@foo.com', participantEmail.toString())
  },

  ratingCalibrationPanelistGetSessionParticipantCycles: (cycleId, sessionId, participantId) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/calibration_session_panelist/calibration_sessions/:session_id/participants/:participant_id/cycles'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':session_id', sessionId.toString()).replace(':participant_id', participantId.toString())
  },

  sessionAdminGetNominations: (cycleId, id) => {
    const pathTemplate = '/api/v1/cycles/:cycle_id/hr_partner/:hr_partner_id/nominations'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
      .replace(':hr_partner_id', id.toString())
  },

  downloadNominations: (cycleId) => {
    const pathTemplate = '/api/v1/admin/cycles/:cycle_id/download_nomination_jobs'
    return pathTemplate.replace(':cycle_id', cycleId.toString())
  },

  spoofUserPath: () => '/api/v1/admin/spoof',

  createUserFeedback: () => '/api/v1/user_feedback',

  createRealtimeFeedback: () => '/api/v1/realtime_feedbacks',

  realtimeFeedback: (limit, offset) => `/api/v1/realtime_feedbacks?limit=${limit}&offset=${offset}`,

  receivedRealtimeFeedbackUserEmails: () => '/api/v1/realtime_feedbacks/received_feedback_user_emails',

  sentRealtimeFeedbackUserEmails: () => '/api/v1/realtime_feedbacks/sent_feedback_user_emails',

  directReportFeedbackRevieweeReviewer: (userIdsStr, secondaryUserIdsStr) => (
    `/api/v1/realtime_feedbacks/direct_report_feedback_reviewee_and_reviewer?${userIdsStr}&${secondaryUserIdsStr}`
  ),

  realtimeFeedbackReceived: (limit, offset, userIdsStr, days) => (
    `/api/v1/realtime_feedbacks/received?limit=${limit}&offset=${offset}&days=${days}&${userIdsStr}`
  ),

  realtimeFeedbackReceivedUnreadCount: (userIdsStr, days) => (
    `/api/v1/realtime_feedbacks/received_unread_count?&days=${days}&${userIdsStr}`
  ),

  realtimeFeedbackSent: (limit, offset, userIdsStr, days) => (
    `/api/v1/realtime_feedbacks/sent?limit=${limit}&offset=${offset}&days=${days}&${userIdsStr}`
  ),

  directReportRealtimeFeedbacks: (limit, offset, userIdsStr, secondaryUserIdsStr, days) => (
    `/api/v1/realtime_feedbacks/direct_reports_feedbacks?limit=${limit}&offset=${offset}&days=${days}&${userIdsStr}&${secondaryUserIdsStr}`
  ),

  updateHasBeenViewedByReviewee: (id) => {
    const pathTemplate = '/api/v1/realtime_feedbacks/:realtime_feedback_id/update_has_been_viewed_by_reviewee'
    return pathTemplate.replace(':realtime_feedback_id', id.toString())
  },
}

export default RailsRouter
