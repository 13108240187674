import React, {
  createContext, ReactChild, ReactFragment, ReactPortal, useEffect, useState,
} from 'react'
import { getConfig } from '../api/config'
import Loader from '../components/common/Loader'

export type Config = {
  GOOGLE_CLIENT_ID: string,
  SENTRY_DSN: string,
  GA_GTAG_ID: string,
  RAILS_ENVIRONMENT: string
  DEPLOYMENT_ENVIRONMENT: string
}

type ConfigProviderProps = {
  children: ReactChild | ReactFragment | ReactPortal
}

const blankConfig: Config = {
  RAILS_ENVIRONMENT: '',
  GA_GTAG_ID: '',
  GOOGLE_CLIENT_ID: '',
  SENTRY_DSN: '',
  DEPLOYMENT_ENVIRONMENT: '',
}

export const ConfigContext = createContext(blankConfig)

export default function ConfigProvider({ children }: ConfigProviderProps) {
  const [isLoadingConfig, setIsLoadingConfig] = useState(true)
  const [config, setConfig] = useState(blankConfig)

  useEffect(() => {
    getConfig().then((res) => {
      setConfig(res.config)
      setIsLoadingConfig(false)
    })
  }, [])

  return (
    <ConfigContext.Provider
      value={config}
    >
      { isLoadingConfig
        ? <Loader loaderText="" />
        : (
          <>{ children }</>
        ) }
    </ConfigContext.Provider>
  )
}
