import React, {
  useState, createContext, useContext, useEffect,
} from 'react'
import { Redirect, useRouteMatch, useLocation } from 'react-router-dom'
import { getRouteConfig } from '../api/config'
import Loader from '../components/common/Loader'
import { UserContext } from './User'

export const RouteConfigContext = createContext({})

export default function RouteConfigProvider({ children }) {
  const [routeOptions, setRouteOptions] = useState({})
  const [isLoadingRouteOptions, setIsLoadingRouteOptions] = useState(true)
  const location = useLocation()
  const isLoginRoute = Boolean(useRouteMatch({ path: '/app/login', strict: false, sensitive: false }))

  const { user } = useContext(UserContext)

  useEffect(() => {
    if (user) {
      setIsLoadingRouteOptions(true)
      getRouteConfig().then((res) => {
        setRouteOptions(res.route_config)
        setIsLoadingRouteOptions(false)
      }, () => {})
    } else {
      setIsLoadingRouteOptions(true)
    }
  }, [user])

  if (!user && !isLoginRoute) {
    return (
      <RouteConfigContext.Provider value={{ routeOptions }}>
        <Redirect to={{ pathname: '/app/login', referrer: `${location.pathname}${location.search}` }} />
      </RouteConfigContext.Provider>
    )
  }

  if (!user) {
    return (
      <RouteConfigContext.Provider value={{ routeOptions }}>
        {children}
      </RouteConfigContext.Provider>
    )
  }

  return (
    <RouteConfigContext.Provider value={{ routeOptions }}>
      {isLoadingRouteOptions ? <Loader /> : <>{children}</>}
    </RouteConfigContext.Provider>
  )
}
